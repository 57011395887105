<template>
	<div class="networks">
		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageNetworkLanding" :isBannerReady="isContentReady"></gadget-ads-banner>

			<h1 class="mt-4">
				NETWORK SHOP
				<br />
				ร้านยางที่มีเครือข่ายทั่วประเทศ
			</h1>

			<div class="mt-4">
				<network-list :networks="networkList"></network-list>
			</div>

			<article class="mt-4">
				<p>ศูนย์บริการรถยนต์แบบเร่งด่วน (FAST FIT) ในประเทศไทย คือศูนย์บริการที่ให้บริการซ่อมบำรุงและบำรุงรักษารถยนต์แบบครบวงจร โดยเน้นการให้บริการที่รวดเร็ว ไม่ต้องรอคิวนาน และมักตั้งอยู่ในทำเลที่สะดวก เช่น ห้างสรรพสินค้า ปั๊มน้ำมัน หรือพื้นที่ใจกลางเมือง โดยบริการหลักที่มีคือ เปลี่ยนยางรถยนต์ ปะยาง ตั้งศูนย์ ถ่วงล้อ เติมลมไนโตรเจน ตรวจเช็กรถยนต์ เปลี่ยนถ่ายน้ำมันเครื่อง เปลี่ยนแบตเตอรี่ เป็นต้น<br>&nbsp;</p>
				<p><strong>แบรนด์ศูนย์บริการ FAST FIT ที่มีชื่อเสียงในไทย ได้แก่ :</strong></p>
				<ul>
					<li>ACDELCO</li>
					<li>AUTOBACS</li>
					<li>AUTO1</li>
					<li>AUTOCLIK</li>
					<li>B-QUIK</li>
					<li>COCKPIT</li>
					<li>DUNLOP SHOP</li>
					<li>FIT AUTO</li>
					<li>FIX FIT</li>
					<li>GOODYEAR AUTOCARE</li>
					<li>GRIP</li>
					<li>MAXXIS TIRE CENTER</li>
					<li>MMS BOSCH CAR SERVICE</li>
					<li>QUICK LANE</li>
					<li>SAVE TYRE</li>
					<li>TYREPLUS</li>
					<li>YOKOHAMA CLUB NETWORK</li>
				</ul>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>AUTOBACS: ออโต้แบคส์ ศูนย์บริการรถยนต์มาตรฐานจากญี่ปุ่น</strong></span></p>
				<p>Autobacs เครือข่ายศูนย์บริการและร้านค้าจำหน่ายอุปกรณ์เกี่ยวกับรถยนต์จากประเทศญี่ปุ่น ซึ่งให้บริการทั้งการเปลี่ยนยางรถยนต์ น้ำมันเครื่อง แบตเตอรี่ ผ้าเบรก รวมถึงการจำหน่ายอุปกรณ์ตกแต่งรถยนต์ และอุปกรณ์บำรุงรักษาต่าง ๆ</p>
				<p><strong>จุดเด่นของ Autobacs</strong></p>
				<ul>
					<li>ศูนย์บริการแบบ FAST FIT บริการรวดเร็ว ไม่ต้องรอคิวนาน</li>
					<li>จำหน่ายอะไหล่และอุปกรณ์ตกแต่งรถยนต์หลากหลาย</li>
					<li>บริการโดยช่างผู้เชี่ยวชาญมาตรฐานญี่ปุ่น</li>
					<li>สินค้านำเข้าจากญี่ปุ่น เช่น น้ำมันเครื่อง เครื่องเสียง อุปกรณ์ตกแต่ง</li>
				</ul>
				<p><strong>บริการหลักของ Autobacs</strong></p>
				<ul>
					<li>เปลี่ยนยางรถยนต์</li>
					<li>เปลี่ยนน้ำมันเครื่อง</li>
					<li>ตั้งศูนย์-ถ่วงล้อ</li>
					<li>เปลี่ยนแบตเตอรี่</li>
					<li>บริการตรวจเช็กสภาพรถ</li>
					<li>ติดตั้งอุปกรณ์ตกแต่งรถยนต์ เช่น กล้องติดรถยนต์</li>
				</ul>
				<p>&nbsp;</p>
				<p>Autobacs มีสาขา 118 สาขาในประเทศไทย (อัปเดต 26 กุมภาพันธ์ 2568) โดยมีร้านจำหน่ายสินค้ารถยนต์และศูนย์บริการครบวงจร กระจายตามเมืองใหญ่ เช่น กรุงเทพฯ และปริมณฑล</p>
				<p>Autobacs เหมาะสำหรับผู้ที่ต้องการ ศูนย์บริการคุณภาพมาตรฐานญี่ปุ่น และมีตัวเลือกสินค้าเกี่ยวกับรถยนต์ให้เลือกหลากหลายกว่าศูนย์ FAST FIT ทั่วไป</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>AUTO 1: ออโต้วัน ศูนย์บริการรถยนต์ครบวงจร ในเครือเซ็นทรัล</strong></span></p>
				<p>&nbsp;</p>
				<p>AUTO 1 ศูนย์บริการรถยนต์มาตรฐานในเครือ Central Retail เปิดให้บริการตั้งแต่ปี 2561 โดยมุ่งเน้นการดูแลรักษารถยนต์ครบวงจร ด้วยเทคโนโลยีทันสมัยและทีมช่างผู้เชี่ยวชาญ ปัจจุบันมี 41 สาขาทั่วประเทศ และมีแผนขยายเพิ่มเพื่อรองรับความต้องการของลูกค้า (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p><br><strong>บริการของ AUTO 1</strong></p>
				<ul>
					<li>เปลี่ยนและดูแลรักษายางรถยนต์</li>
					<li>ตั้งศูนย์-ถ่วงล้อ</li>
					<li>เปลี่ยนถ่ายน้ำมันเครื่องและไส้กรอง</li>
					<li>ซ่อมระบบเบรกและช่วงล่าง</li>
					<li>ตรวจเช็กระบบไฟฟ้าและแอร์รถยนต์</li>
					<li>เปลี่ยนแบตเตอรี่และใบปัดน้ำฝน</li>
					<li>เติมลมไนโตรเจน<br>&nbsp;</li>
				</ul>
				<p>AUTO 1 ให้บริการกับรถยนต์ทุกประเภท เช่น SUV, รถสปอร์ต, รถไฮบริด และ EV พร้อมให้คำแนะนำจากผู้เชี่ยวชาญเพื่อดูแลรถของคุณอย่างมีประสิทธิภาพ จองคิวง่ายผ่านแอป AUTO 1<br>เพื่อความสะดวกสูงสุด AUTO 1 พัฒนาระบบแอปพลิเคชันที่ช่วยให้ลูกค้า:</p>
				<ul>
					<li>จองคิวนัดหมายล่วงหน้า ได้รวดเร็ว ไม่ต้องรอที่ศูนย์</li>
					<li>ติดตามสถานะบริการแบบเรียลไทม์</li>
					<li>ดูประวัติการเข้ารับบริการย้อนหลัง</li>
					<li>รับโปรโมชั่นพิเศษก่อนใคร</li>
					<li>ค้นหาสาขาใกล้คุณพร้อมนำทางอัตโนมัติ</li>
				</ul>
				<p><br>AUTO 1 - ดูแลรถคุณอย่างมืออาชีพ สะดวก รวดเร็ว มาตรฐานศูนย์บริการชั้นนำ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>AUTOCLIK: ออโตคลิก ศูนย์บริการรถยนต์แบบเร่งด่วน (Fast Fit) สำหรับทุกยี่ห้อ</strong></span></p>
				<p>&nbsp;</p>
				<p>AUTOCLIK (ออโตคลิก) เป็นศูนย์บริการซ่อมบำรุงและดูแลรักษารถยนต์แบบเร่งด่วน (Fast Fit) โดยไม่ต้องนัดหมายล่วงหน้า ให้บริการครอบคลุมทุกยี่ห้อ ด้วยมาตรฐานคุณภาพสูงและเครื่อง<br>มือทันสมัย โดยมีบริการหลัก ปัจจุบัน Autoclik มี 15 สาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p><br><strong>บริการของ Autoclik ได้แก่:</strong></p>
				<ul>
					<li>เปลี่ยนถ่ายน้ำมันเครื่อง</li>
					<li>เปลี่ยนยางรถยนต์</li>
					<li>เปลี่ยนแบตเตอรี่</li>
					<li>ตั้งศูนย์ถ่วงล้อ</li>
					<li>ซ่อมบำรุงระบบเบรก ช่วงล่าง และโช้คอัพ</li>
					<li>ตรวจเช็กและซ่อมบำรุงเบื้องต้น</li>
					<li>ซ่อมบำรุงระบบแอร์รถยนต์</li>
				</ul>
				<p>AUTOCLIK เป็นบริษัทในเครือ Auto Corporation Group (ACG) ซึ่งจดทะเบียนในตลาดหลักทรัพย์แห่งประเทศไทย (SET) ภายใต้ชื่อย่อหลักทรัพย์ “ACG” ดำเนินธุรกิจในกลุ่มอุตสาหกรรมยานยนต์ โดยมีเป้าหมายให้บริการที่มีคุณภาพ รวดเร็ว และคุ้มค่า</p>
				<p>&nbsp;</p>
				<p>บริษัท ออโตคลิก บาย เอซีจี จำกัด (Autoclik By ACG Co., Ltd.) ก่อตั้งเมื่อวันที่ 8 กรกฎาคม 2563 โดยเปิดสาขาแรกที่ถนนเทพกระษัตรี จังหวัดภูเก็ต เมื่อวันที่ 1 มีนาคม 2564 และมีแผนขยายสาขาอย่างต่อเนื่องในพื้นที่ต่างๆ เช่น กรุงเทพฯ ปริมณฑล ภูเก็ต และเชียงใหม่<br>มาตรฐานการให้บริการ AUTOCLIK มุ่งเน้นการให้บริการที่มีคุณภาพ ด้วยทีมช่างผู้เชี่ยวชาญ พร้อมเครื่องมือมาตรฐานที่ทันสมัย ภายใต้แนวคิด "จ่ายหลักร้อย... รับบริการแบบหลักหมื่น" เพื่อสร้างความพึงพอใจสูงสุดให้แก่ลูกค้า</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>B-Quik: บี-ควิก ศูนย์บริการรถยนต์ครบวงจร มาตรฐานระดับสากล</strong></span></p>
				<p>&nbsp;</p>
				<p>B-Quik (บี-ควิก) เป็นศูนย์บริการยางและบำรุงรักษารถยนต์ครบวงจรที่ได้รับความไว้วางใจจากผู้ใช้รถทั่วประเทศ เราพร้อมให้บริการอย่างมืออาชีพ ครอบคลุมทุกความต้องการ ตั้งแต่การเปลี่ยนยาง ตั้งศูนย์-ถ่วงล้อ เปลี่ยนน้ำมันเครื่อง ระบบช่วงล่าง เบรก แบตเตอรี่ ระบบแอร์รถยนต์ ไปจนถึงงานบำรุงรักษาทั่วไป ด้วยอุปกรณ์ทันสมัยและทีมช่างผู้เชี่ยวชาญที่ผ่านการอบรมมาโดยเฉพาะ ปัจจุบัน B-Quik มีสาขา 224 แห่งทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p>&nbsp;</p>
				<p><strong>ทำไมต้องเลือก B-Quik?</strong></p>
				<ul>
					<li>มาตรฐานสูงสุด: บริการคุณภาพ ควบคุมมาตรฐานเดียวกันทุกสาขา</li>
					<li>รวดเร็วและสะดวก: มีอะไหล่พร้อมเปลี่ยน ไม่ต้องรอนาน</li>
					<li>รับประกันคุณภาพ: กล้ารับประกันสินค้าและบริการ คืนเงินหรือเปลี่ยนอะไหล่ใหม่ทันทีหากพบปัญหา</li>
					<li>เปิดบริการทุกวัน: เข้ารับบริการได้ตลอด ไม่มีวันหยุด</li>
				</ul>
				<p>&nbsp;</p>
				<p><strong>บริการของ B-Quik</strong></p>
				<ul>
					<li>เปลี่ยนและซ่อมบำรุงยางรถยนต์</li>
					<li>ตั้งศูนย์-ถ่วงล้อ เติมลมไนโตรเจน</li>
					<li>เปลี่ยนน้ำมันเครื่อง และไส้กรอง</li>
					<li>ตรวจสอบและเปลี่ยนแบตเตอรี่</li>
					<li>ซ่อมระบบช่วงล่าง โช้คอัพ และเบรก</li>
					<li>ตรวจสอบระบบไฟ เช่น หัวเทียน หลอดไฟ ฟิวส์</li>
					<li>ซ่อมและบำรุงรักษาระบบแอร์รถยนต์</li>
					<li>บริการเปลี่ยนใบปัดน้ำฝน</li>
				</ul>
				<p>&nbsp;</p>
				<p>B-Quik มุ่งเน้นการให้บริการที่ครบวงจรและมีมาตรฐานระดับสากล ตอบโจทย์เจ้าของรถทุกประเภท ไม่ว่าคุณจะใช้รถยี่ห้อไหน เราพร้อมดูแลคุณ ให้คุณมั่นใจในความปลอดภัยทุกการเดินทาง<br>B-Quik – เราดูแล...คุณแค่ขับ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>COCKPIT: ค็อกพิทศูนย์บริการยางและบำรุงรักษารถยนต์ครบวงจ</strong></span>ร</p>
				<p>&nbsp;</p>
				<p>COCKPIT (ค็อกพิท) เป็นเครือข่ายศูนย์บริการยางรถยนต์และบำรุงรักษารถแบบครบวงจร ภายใต้การบริหารของ บริดจสโตน (Bridgestone) เปิดตัวสาขาแรกในประเทศไทยเมื่อปี 2534 และเริ่มขยายผ่านระบบแฟรนไชส์ตั้งแต่ปี 2539 ปัจจุบันมี 274 สาขาทั่วประเทศ ครอบคลุมทั้งกรุงเทพฯ และต่างจังหวัด (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p>&nbsp;</p>
				<p><strong>บริการหลักของ COCKPIT</strong></p>
				<ul>
					<li>จำหน่ายและเปลี่ยนยางรถยนต์ทุกประเภท</li>
					<li>ตั้งศูนย์ ถ่วงล้อ และสลับยาง</li>
					<li>เปลี่ยนถ่ายน้ำมันเครื่อง</li>
					<li>ตรวจเช็กและซ่อมบำรุงระบบเบรก ช่วงล่าง และแบตเตอรี่</li>
					<li>ซ่อมบำรุงระบบปรับอากาศรถยนต์</li>
					<li>บริการตรวจสภาพรถยนต์เบื้องต้น</li>
				</ul>
				<p>&nbsp;</p>
				<p><strong>จุดเด่นของ COCKPIT</strong></p>
				<ul>
					<li>ใช้ผลิตภัณฑ์คุณภาพจาก Bridgestone, Firestone, Dayton และแบรนด์ชั้นนำ </li>
					<li>ทีมช่างมืออาชีพ ผ่านการอบรมมาตรฐานจากบริดจสโตน </li>
					<li>ครอบคลุมสาขาทั่วประเทศ บริการสะดวก รวดเร็ว </li>
					<li>เทคโนโลยีทันสมัย พร้อมเครื่องมือมาตรฐานสูง</li>
					<li>
						<p>บริการรวดเร็ว ไม่ต้องรอนาน คุ้มค่าทุกการใช้บริการ</p>
						<p>&nbsp;</p>
					</li>
				</ul>
				<p>COCKPIT ตอบโจทย์ไลฟ์สไตล์คนรุ่นใหม่ด้วยมาตรฐานการบริการที่ทันสมัย พร้อมโปรโมชั่นพิเศษ และโซลูชันดูแลรถยนต์แบบครบวงจร สนใจเช็กราคายาง จองคิวออนไลน์ หรือค้นหาสาขาใกล้คุณได้ที่เว็บไซต์ COCKPIT ศูนย์บริการที่คุณวางใจตลอดเส้นทาง<br>“คุ้ม ครบ ไว อุ่นใจที่ COCKPIT”</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>Dunlop Shop: ดันลอปช็อป ศูนย์บริการยางรถยนต์ครบวงจร มาตรฐานระดับโลก เพื่อความปลอดภัยทุกการเดินทาง</strong></span></p>
				<p>&nbsp;</p>
				<p>Dunlop Shop คือเครือข่ายศูนย์บริการยางรถยนต์ภายใต้แบรนด์ Dunlop ผู้ผลิตยางรถยนต์ชั้นนำระดับโลกที่มีประวัติยาวนานกว่า 130 ปี ด้วยความเชี่ยวชาญและประสบการณ์ Dunlop Shop มุ่งมั่นที่จะมอบบริการคุณภาพสูงและครบวงจรแก่ลูกค้าทุกท่าน เพื่อความปลอดภัยและประสิทธิภาพในการขับขี่ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ Dunlop Shop:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยาง Dunlop คุณภาพสูงหลากหลายรุ่น</li>
					<li>บริการยาง: เปลี่ยนยาง, ตั้งศูนย์ถ่วงล้อ, เติมลมไนโตรเจน</li>
					<li>บริการบำรุงรักษารถยนต์: ตรวจเช็กและบำรุงรักษารถยนต์เบื้องต้น เช่น เปลี่ยนถ่ายน้ำมันเครื่อง และตรวจสอบระบบช่วงล่าง</li>
				</ul>
				<p>&nbsp;</p>
				<p><strong>จุดเด่นของ Dunlop Shop:</strong></p>
				<ul>
					<li>มาตรฐาน Dunlop: บริการด้วยมาตรฐานและผลิตภัณฑ์จาก Dunlop</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมและมีประสบการณ์</li>
					<li>บริการครบวงจร: ดูแลรักษารถยนต์ครบทุกด้าน</li>
					<li>
						<p>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ</p>
						<p>&nbsp;</p>
					</li>
				</ul>
				<p>Dunlop Shop มุ่งมั่นที่จะเป็นศูนย์บริการยางรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>FIT Auto: ฟิตออโต้ ศูนย์บริการรถยนต์ครบวงจร เพื่อการดูแลรถยนต์อย่างมืออาชีพ</strong></span></p>
				<p>&nbsp;</p>
				<p>FIT Auto (ฟิตออโต้) เป็นศูนย์บริการยานยนต์ครบวงจรภายใต้การบริหารของ บริษัท ปตท. น้ำมันและการค้าปลีก จำกัด (มหาชน) (OR) ที่มุ่งเน้นการให้บริการบำรุงรักษารถยนต์อย่างมืออาชีพและครบวงจร ด้วยประสบการณ์กว่า 10 ปีจากศูนย์เปลี่ยนถ่ายน้ำมันเครื่อง PRO Check สู่ FIT Auto ที่ทันสมัยและตอบโจทย์ทุกความต้องการของคนรักรถ ปัจจุบัน Fit Auto มี 108 สาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ FIT Auto:</strong></p>
				<ul>
					<li>การเปลี่ยนถ่ายน้ำมันเครื่อง: บริการเปลี่ยนถ่ายน้ำมันเครื่องคุณภาพสูง พร้อมให้คำแนะนำโดยช่างผู้เชี่ยวชาญ</li>
					<li>การเปลี่ยนยาง: บริการเปลี่ยนยางหลากหลายยี่ห้อ พร้อมบริการตั้งศูนย์ถ่วงล้อ</li>
					<li>ระบบช่วงล่างและเบรก: ตรวจเช็กและซ่อมบำรุงระบบช่วงล่างและเบรกเพื่อความปลอดภัยในการขับขี่</li>
					<li>แบตเตอรี่: บริการเปลี่ยนแบตเตอรี่คุณภาพ พร้อมตรวจเช็กระบบไฟฟ้า</li>
					<li>ตรวจเช็กสภาพรถยนต์: บริการตรวจเช็กสภาพรถยนต์แบบครบวงจร เพื่อความปลอดภัยในการขับขี่</li>
					<li>คาร์แคร์: บริการล้างทำความสะอาดและดูแลรักษาสีรถยนต์แบบครบวงจร</li>
				</ul>
				<p>&nbsp;</p>
				<p><strong>จุดเด่นของ FIT Auto:</strong></p>
				<ul>
					<li>บริการครบวงจร (One-Stop-Service): ตอบโจทย์ทุกความต้องการในการดูแลรถยนต์ในที่เดียว</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ทีมช่างมากประสบการณ์ พร้อมให้บริการด้วยความใส่ใจ</li>
					<li>เครื่องมือและเทคโนโลยีทันสมัย: มั่นใจในคุณภาพและมาตรฐานการบริการ</li>
					<li>เครือข่ายสาขาทั่วประเทศ: สะดวกสบายในการเข้ารับบริการ</li>
					<li>ความมั่นใจจาก OR: ดำเนินงานโดยบริษัทในเครือ ปตท.</li>
				</ul>
				<p>&nbsp;</p>
				<p>FIT Auto มุ่งมั่นที่จะเป็นศูนย์บริการรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับมืออาชีพ เพื่อให้ทุกการขับขี่ของคุณปลอดภัยและมั่นใจ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>FIX FIT: ฟิกซ์ฟิต ศูนย์บริการรถยนต์แบบเร่งด่วน ซ่อมรถทุกยี่ห้อ</strong></span></p>
				<p>&nbsp;</p>
				<p>FIX FIT เป็นศูนย์บริการรถยนต์ภายใต้การควบคุมคุณภาพของบริษัท โตโยต้า มอเตอร์ ประเทศไทย จำกัด ให้บริการซ่อมบำรุงและบำรุงรักษารถยนต์ทุกยี่ห้อ โดยเน้นการให้บริการที่รวดเร็วและมีมาตรฐานสูง</p>
				<p>&nbsp;</p>
				<p><strong>บริการหลักของ FIX FIT:</strong></p>
				<ul>
					<li>ตรวจเช็กระยะทั่วไป: บริการตรวจเช็กสภาพรถยนต์ตามระยะทาง เพื่อความปลอดภัยและประสิทธิภาพในการขับขี่</li>
					<li>คาร์แคร์ครบวงจร: บริการทำความสะอาดและดูแลรักษารถยนต์ทั้งภายในและภายนอก</li>
					<li>เซอร์วิสลูกค้านอกสถานที่: บริการซ่อมบำรุงรถยนต์ถึงที่ ไม่ว่าจะเป็นที่บ้าน ที่ทำงาน หรือระหว่างการเดินทาง ในพื้นที่กรุงเทพฯ และปริมณฑล&nbsp;</li>
				</ul>
				<p>&nbsp;</p>
				<p>ปัจจุบัน FIX FIT มีสาขาให้บริการ 14 สาขา (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>Goodyear Autocare: กู๊ดเยียร์ ออโต้แคร์ ศูนย์บริการยางและรถยนต์ครบวงจร มาตรฐานระดับโลก เพื่อความปลอดภัยทุกการเดินทาง</strong></span></p>
				<p><br>Goodyear Autocare คือเครือข่ายศูนย์บริการยางและการบำรุงรักษารถยนต์ครบวงจรภายใต้แบรนด์ Goodyear ผู้นำด้านยางรถยนต์ระดับโลก ด้วยประสบการณ์กว่า 100 ปี Goodyear Autocare มุ่งมั่นที่จะมอบบริการคุณภาพสูงและครบวงจรแก่ลูกค้าทุกท่าน เพื่อความปลอดภัยและประสิทธิภาพในการขับขี่ ปัจจุบัน Goodyear Autocare มีกว่า 70 สาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p><br><strong>บริการครบวงจรที่ Goodyear Autocare:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยาง Goodyear คุณภาพสูงหลากหลายรุ่น</li>
					<li>บริการยาง: ตั้งศูนย์ถ่วงล้อ</li>
					<li>บริการบำรุงรักษารถยนต์: เปลี่ยนถ่ายน้ำมันเครื่อง, ตรวจเช็กช่วงล่าง, เปลี่ยนแบตเตอรี่</li>
					<li>บริการตรวจเช็กสภาพรถยนต์: ตรวจเช็กมาตรฐาน 25 รายการ</li>
				</ul>
				<p><br><strong>จุดเด่นของ Goodyear Autocare:</strong></p>
				<ul>
					<li>มาตรฐาน Goodyear: บริการด้วยมาตรฐานและผลิตภัณฑ์จาก Goodyear</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมและมีประสบการณ์</li>
					<li>บริการครบวงจร: ดูแลรักษารถยนต์ครบทุกด้าน</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ</li>
					<li>การฝึกอบรมบุคลากรอย่างสม่ำเสมอ: ด้วยวิทยากรประจำมืออาชีพ</li>
				</ul>
				<p><br>Goodyear Autocare มุ่งมั่นที่จะเป็นศูนย์บริการยางและรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
				<p><br><span style="font-size:20px;"><strong>GRIP: กริพ ศูนย์บริการยางรถยนต์ครบวงจร เพื่อความปลอดภัยทุกการเดินทาง&nbsp;</strong></span></p>
				<p>&nbsp;</p>
				<p>GRIP คือเครือข่ายศูนย์บริการและจำหน่ายยางรถยนต์ครบวงจร ภายใต้การดูแลของบริษัท ต.สยาม คอมเมอร์เชียล จำกัด ผู้นำเข้าและจัดจำหน่ายยาง TOYO TIRES และ NITTO TIRE อย่างเป็นทางการในประเทศไทย ด้วยประสบการณ์และความเชี่ยวชาญในอุตสาหกรรมยางรถยนต์ GRIP มุ่งมั่นที่จะมอบบริการคุณภาพสูง เพื่อความปลอดภัยและประสิทธิภาพในการขับขี่ของลูกค้าทุกท่าน ปัจจุบัน GRIP มี 60 กว่าสาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)<br>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ GRIP:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยางจากแบรนด์ชั้นนำระดับโลก เช่น TOYO TIRES, NITTO TIRE และ KUMHO TIRE</li>
					<li>ติดตั้งยางและตั้งศูนย์ถ่วงล้อ: บริการติดตั้งยางและตั้งศูนย์ถ่วงล้อด้วยเครื่องมือที่ทันสมัย</li>
					<li>บริการดูแลรักษารถยนต์: บริการเปลี่ยนถ่ายน้ำมันเครื่อง ตรวจเช็กช่วงล่าง และบำรุงรักษาระบบต่างๆ ของรถยนต์</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ทีมช่างที่ผ่านการฝึกอบรมมาอย่างดี พร้อมให้คำแนะนำและบริการอย่างมืออาชีพ</li>
				</ul>
				<p>&nbsp;</p>
				<p><strong>จุดเด่นของ GRIP:</strong></p>
				<ul>
					<li>มาตรฐานระดับสากล: บริการด้วยเครื่องมือและเทคโนโลยีที่ทันสมัย</li>
					<li>ความปลอดภัยเป็นหลัก: มุ่งเน้นความปลอดภัยในการขับขี่ของลูกค้า</li>
					<li>บริการครบวงจร: ตอบโจทย์ทุกความต้องการในการดูแลยางรถยนต์และรถยนต์</li>
					<li>เครือข่ายสาขาทั่วประเทศ: สะดวกสบายในการเข้ารับบริการ</li>
					<li>สนับสนุนร้านยางรายย่อย: ช่วยยกระดับมาตรฐานร้านยางรายย่อยให้เทียบเท่ามืออาชีพ<br>&nbsp;</li>
				</ul>
				<p>GRIP มุ่งมั่นที่จะเป็นศูนย์บริการยางรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับสากล เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ&nbsp;<br>"GRIP ที่เดียว จบเรื่องยาง"</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>Maxxis Tire Center (MTC): แม็กซิส ไทร์ เซ็นเตอร์ ศูนย์บริการยางมาตรฐานจากไต้หวัน เพื่อความทนทานและปลอดภัยทุกการเดินทาง</strong></span></p>
				<p>&nbsp;</p>
				<p>Maxxis Tire Center (MTC) คือเครือข่ายศูนย์บริการยางรถยนต์มาตรฐานภายใต้แบรนด์ Maxxis ผู้นำด้านยางรถยนต์จากไต้หวัน ที่มีชื่อเสียงด้านความทนทานและสมรรถนะในการขับขี่ ด้วยประสบการณ์กว่า 50 ปี Maxxis มุ่งมั่นที่จะมอบบริการคุณภาพสูงและครบวงจรแก่ลูกค้าทุกท่าน (อัปเดต 26 กุมภาพันธ์ 2568)<br>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ MTC:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยาง Maxxis คุณภาพสูงหลากหลายรุ่น</li>
					<li>บริการยาง: เปลี่ยนยาง, ตั้งศูนย์ถ่วงล้อ, เติมลมไนโตรเจน</li>
					<li>บริการบำรุงรักษารถยนต์: เปลี่ยนถ่ายน้ำมันเครื่อง, ตรวจเช็กแบตเตอรี่<br>&nbsp;</li>
				</ul>
				<p><strong>จุดเด่นของ MTC:</strong></p>
				<ul>
					<li>มาตรฐาน Maxxis: บริการด้วยมาตรฐานและผลิตภัณฑ์จาก Maxxis</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมและมีประสบการณ์</li>
					<li>บริการครบวงจร: ดูแลรักษารถยนต์ครบทุกด้าน</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ</li>
					<li>คุณภาพระดับโลก: Maxxis เป็นผู้ผลิตยางรถยนต์รายใหญ่ที่สุดครองอันดับที่ 9 ของโลก</li>
				</ul>
				<p><br>MTC มุ่งมั่นที่จะเป็นศูนย์บริการยางรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>MMS Bosch Car Service: เอ็มเอ็มเอส บ๊อช ศูนย์บริการรถยนต์ครบวงจร มาตรฐานระดับโลก</strong></span></p>
				<p><br>MMS Bosch Car Service เป็นศูนย์บริการรถยนต์ครบวงจรภายใต้การบริหารของ บริษัท มาสเตอร์ กรุ๊ป คอร์ปอเรชั่น (เอเชีย) จำกัด (MGC-ASIA) ได้รับความไว้วางใจจาก Bosch แบรนด์เทคโนโลยียานยนต์ชั้นนำจากเยอรมนี ให้เป็น Preferred Authorized Partner ในการขยายและยกระดับศูนย์บริการในประเทศไทย ด้วยประสบการณ์และความเชี่ยวชาญกว่า 20 ปี MMS มุ่งมั่นที่จะมอบบริการคุณภาพสูงสุดแก่ลูกค้าทุกท่าน ปัจจุบัน MMS Bosch Car Service มี 22 สาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)<br>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ MMS Bosch Car Service:</strong></p>
				<ul>
					<li>ตรวจเช็กและซ่อมบำรุง: เครื่องยนต์, เกียร์, ช่วงล่าง, เบรก, คลัตช์, ระบบไฟฟ้า, ระบบปรับอากาศ</li>
					<li>บริการเปลี่ยนถ่าย: น้ำมันเครื่อง, แบตเตอรี่, ล้อยาง</li>
					<li>เช็กระยะ: บริการเช็กระยะตามมาตรฐานผู้ผลิต</li>
					<li>บริการสำหรับลูกค้าองค์กร: บริการดูแลรักษารถยนต์สำหรับบริษัทและองค์กร<br>&nbsp;</li>
				</ul>
				<p><strong>จุดเด่นของ MMS Bosch Car Service:</strong></p>
				<ul>
					<li>มาตรฐาน Bosch: ใช้เครื่องมือและเทคโนโลยีที่ทันสมัยจาก Bosch</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมจาก Bosch</li>
					<li>อะไหล่แท้คุณภาพ: ใช้อะไหล่มาตรฐานที่ผู้ผลิตรถยนต์เลือกใช้</li>
					<li>บริการครบวงจร: ดูแลรถยนต์ทุกรุ่น ทั้งรถยุโรป, รถญี่ปุ่น และรถนำเข้า</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่กรุงเทพฯ, ปริมณฑล และต่างจังหวัด</li>
					<li>บริการลูกค้าองค์กร: บริการที่ตอบโจทย์ความต้องการของบริษัทและองค์กร</li>
				</ul>
				<p>MMS Bosch Car Service มุ่งมั่นที่จะเป็นศูนย์บริการรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>Quick Lane: ควิกเลน ศูนย์บริการรถยนต์และยางมาตรฐานระดับโลก เพื่อความสะดวกและปลอดภัยทุกการเดินทาง</strong></span></p>
				<p><br>Quick Lane คือเครือข่ายศูนย์บริการซ่อมบำรุงและดูแลรักษารถยนต์ครบวงจร ก่อตั้งโดย Ford Motor Company ในสหรัฐอเมริกา และขยายสาขาไปทั่วโลก ด้วยมาตรฐานระดับสากลและทีมช่างผู้เชี่ยวชาญ Quick Lane มุ่งมั่นที่จะมอบบริการคุณภาพสูง รวดเร็ว และสะดวกสบายแก่ลูกค้าทุกท่าน ปัจจุบัน Quick Lane มี 17 สาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)<br>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ Quick Lane:</strong></p>
				<ul>
					<li>บริการยาง: เปลี่ยนยาง, ตั้งศูนย์ถ่วงล้อ, สลับยาง</li>
					<li>บริการน้ำมันเครื่อง: เปลี่ยนถ่ายน้ำมันเครื่องและไส้กรอง</li>
					<li>บริการระบบเบรกและช่วงล่าง: ตรวจเช็กและซ่อมบำรุงระบบเบรก, โช้คอัพ, และช่วงล่าง</li>
					<li>บริการแบตเตอรี่: ตรวจเช็กและเปลี่ยนแบตเตอรี่</li>
					<li>บริการตรวจเช็กสภาพรถยนต์: ตรวจเช็กสภาพรถยนต์เบื้องต้น 14 กลุ่มบริการ<br>&nbsp;</li>
				</ul>
				<p><strong>จุดเด่นของ Quick Lane:</strong></p>
				<ul>
					<li>มาตรฐานระดับโลก: บริการด้วยมาตรฐานและเทคโนโลยีจาก Ford</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมและมีประสบการณ์</li>
					<li>บริการรวดเร็วและสะดวก: บริการแบบเร่งด่วน โดยไม่ต้องนัดหมายล่วงหน้า</li>
					<li>บริการครบวงจร: ดูแลรถยนต์ทุกรุ่น ทุกยี่ห้อ</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ<br>&nbsp;</li>
				</ul>
				<p>Quick Lane มุ่งมั่นที่จะเป็นศูนย์บริการรถยนต์และยางที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณสะดวกสบายและปลอดภัย&nbsp;<br>" Quick Lane สะดวก...วางใจได้จริง"</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>Savetyre: เซฟไทร์ ศูนย์บริการยางและรถยนต์ครบวงจร เพื่อความปลอดภัยทุกการเดินทาง</strong></span></p>
				<p><br>Savetyre คือเครือข่ายศูนย์บริการยางและดูแลรักษารถยนต์ที่มุ่งเน้นการให้บริการที่มีคุณภาพสูงและปลอดภัย ด้วยยางคุณภาพจากแบรนด์ชั้นนำ เช่น Goodride, Kumho, Nazz, Blackhawk และบริการครบวงจร Savetyre มุ่งมั่นที่จะมอบบริการที่ดีที่สุดแก่ลูกค้าทุกท่าน ภายใต้การดูแลของบริษัท ไทร์ ทู ยู จำกัด ปัจจุบัน Savetyre มีกว่า 180 สาขา ทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p><br><strong>บริการครบวงจรที่ Savetyre:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยางจากแบรนด์ชั้นนำหลากหลายรุ่น</li>
					<li>บริการยาง: เปลี่ยนยาง, ตั้งศูนย์ถ่วงล้อ, เติมลมไนโตรเจน</li>
					<li>บริการบำรุงรักษารถยนต์: ตรวจเช็กระบบเบรก, ช่วงล่าง, เปลี่ยนถ่ายน้ำมันเครื่อง<br>&nbsp;</li>
				</ul>
				<p><strong>จุดเด่นของ Savetyre:</strong></p>
				<ul>
					<li>คุณภาพและความปลอดภัย: มุ่งเน้นความปลอดภัยและประสิทธิภาพในการขับขี่</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่มีความเชี่ยวชาญและประสบการณ์</li>
					<li>เทคโนโลยีทันสมัย: ใช้เทคโนโลยีที่ทันสมัยในการให้บริการ</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ<br>&nbsp;</li>
				</ul>
				<p>Savetyre มุ่งมั่นที่จะเป็นศูนย์บริการยางและรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานสูง เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>TYREPLUS: ไทร์พลัส ศูนย์บริการยางและรถยนต์ครบวงจร มาตรฐาน Michelin เพื่อความปลอดภัยทุกการเดินทาง</strong></span></p>
				<p><br>TYREPLUS คือเครือข่ายศูนย์บริการยางรถยนต์และการบำรุงรักษารถยนต์ครบวงจรระดับโลก ได้รับการสนับสนุนจาก Michelin ผู้นำด้านยางรถยนต์ระดับโลกในประเทศไทยเปิดให้บริการตั้งแต่ปี พ.ศ. 2546 โดยมุ่งเน้นการให้บริการที่มีคุณภาพและมาตรฐานสูง เพื่อความปลอดภัยและประสิทธิภาพในการขับขี่ของลูกค้า ปัจจุบัน TyrePlus มีกว่า 160 สาขาทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)</p>
				<p><br><strong>บริการครบวงจรที่ TYREPLUS:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยาง Michelin, BF Goodrich และแบรนด์ชั้นนำอื่นๆ</li>
					<li>บริการยาง: ติดตั้งยาง, ตั้งศูนย์ถ่วงล้อ, เติมลมไนโตรเจน, ซ่อมยาง</li>
					<li>บริการบำรุงรักษารถยนต์: เปลี่ยนถ่ายน้ำมันเครื่อง, ตรวจเช็กช่วงล่าง, เปลี่ยนแบตเตอรี่, ตรวจเช็คระบบเบรก</li>
					<li>บริการตรวจเช็กสภาพรถยนต์: ตรวจเช็กสภาพรถยนต์เบื้องต้น</li>
				</ul>
				<p><br><strong>จุดเด่นของ TYREPLUS:</strong></p>
				<ul>
					<li>มาตรฐาน Michelin: บริการด้วยมาตรฐานและเทคโนโลยีจาก Michelin</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมและมีประสบการณ์</li>
					<li>บริการครบวงจร: ดูแลรถยนต์ครบทุกด้าน</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ</li>
					<li>ระบบบันทึกประวัติการซ่อมบำรุง: พร้อมแจ้งเตือนเมื่อถึงรอบการเข้ารับบริการ</li>
				</ul>
				<p><br>TYREPLUS มุ่งมั่นที่จะเป็นศูนย์บริการยางและรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
				<p>&nbsp;</p>
				<p><span style="font-size:20px;"><strong>Yokohama Club Network (YCN): โยโกฮามาคลับเน็ตเวิร์ค ศูนย์บริการยางมาตรฐานจากญี่ปุ่น เพื่อความปลอดภัยทุกการขับขี่&nbsp;</strong></span><br>&nbsp;</p>
				<p>Yokohama Club Network (YCN) คือเครือข่ายศูนย์บริการยางมาตรฐานภายใต้แบรนด์ Yokohama ผู้นำด้านยางรถยนต์จากญี่ปุ่น ออกแบบมาเพื่อตอบสนองความต้องการของผู้ใช้รถยนต์ที่ต้องการยางคุณภาพสูงและบริการที่เชื่อถือได้ ปัจจุบัน Yokohama Club Network (YCN) มีร้ายางกว่า 60 ร้านค้าทั่วประเทศ (อัปเดต 26 กุมภาพันธ์ 2568)<br>&nbsp;</p>
				<p><strong>บริการครบวงจรที่ YCN:</strong></p>
				<ul>
					<li>จำหน่ายยางคุณภาพ: จำหน่ายยาง Yokohama คุณภาพสูงหลากหลายรุ่น</li>
					<li>บริการยาง: เปลี่ยนยาง, ตั้งศูนย์ถ่วงล้อ, เติมลมไนโตรเจน</li>
					<li>บริการบำรุงรักษารถยนต์: เปลี่ยนถ่ายน้ำมันเครื่อง, ตรวจเช็กช่วงล่าง<br>&nbsp;</li>
				</ul>
				<p><strong>จุดเด่นของ YCN:</strong></p>
				<ul>
					<li>มาตรฐาน Yokohama: บริการด้วยมาตรฐานและผลิตภัณฑ์จาก Yokohama</li>
					<li>ทีมช่างผู้เชี่ยวชาญ: ช่างเทคนิคที่ผ่านการฝึกอบรมและมีประสบการณ์</li>
					<li>บริการครบวงจร: ดูแลรักษารถยนต์ครบทุกด้าน</li>
					<li>เครือข่ายสาขาทั่วประเทศ: ครอบคลุมพื้นที่ให้บริการ</li>
				</ul>
				<p><br>YCN มุ่งมั่นที่จะเป็นศูนย์บริการยางรถยนต์ที่ลูกค้าไว้วางใจ ด้วยบริการคุณภาพและมาตรฐานระดับโลก เพื่อให้ทุกการเดินทางของคุณปลอดภัยและมั่นใจ</p>
			</article>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import NetworkService from '@/services/networkService';
import ProductService from '@/services/productService';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import NetworkList from '@/components/network/NetworkList';

export default {
	components: {
		GadgetAdsBanner,
		NetworkList
	},
	data() {
		return {
			isContentReady: false,
			isPopularTireReady: false,
			topBanner: null,
			networkList: null,
			popularTireList: [],
			activePage: 1
		};
	},
	mounted() {
        this.getContent();
		this.getProductResult();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

            const result = await NetworkService.getLandingContent();

			if (result.data) {
				this.topBanner = result.data.ads;
				this.networkList = result.data.network;
			}

			this.isContentReady = true;
        },
		async getProductResult() {
            this.isPopularTireReady = false;

			const param = this.setParamProduct();
            const result = await ProductService.getWeeklyPopularTire(param);

			this.popularTireList = this.popularTireList .concat(result.data);
            this.isPopularTireReady = true;
        },
		setParamProduct() {
			const param = {};

			return param;
		},
		loadMore() {
			this.activePage++;
			this.getProductResult();
		}
    },
	metaInfo() {
		const title = 'ร้านยางที่มีเครือข่ายทั่วประเทศ | YELLOWTiRE';
		const desc = 'ศูนย์บริการรถยนต์แบบเร่งด่วน (FAST FIT) ในประเทศไทย คือศูนย์บริการที่ให้บริการซ่อมบำรุงและบำรุงรักษารถยนต์แบบครบวงจร โดยเน้นการให้บริการที่รวดเร็ว ไม่ต้องรอคิวนาน และมักตั้งอยู่ในทำเลที่สะดวก เช่น ห้างสรรพสินค้า ปั๊มน้ำมัน หรือพื้นที่ใจกลางเมือง โดยบริการหลักที่มีคือ เปลี่ยนยางรถยนต์ ปะยาง ตั้งศูนย์ ถ่วงล้อ เติมลมไนโตรเจน ตรวจเช็กรถยนต์ เปลี่ยนถ่ายน้ำมันเครื่อง เปลี่ยนแบตเตอรี่ เป็นต้น';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.networks {
	padding: 15px 0;
}

h1 {
	font-weight: bold;
	text-align: center;
	font-size: 22px;
}

article {
	margin-bottom: 30px;
	font-size: 13px;
	line-height: 18px;
	padding: 20px;
	background: #fff;

	h2 {
		font-weight: bold;
		font-size: 15px;
	}
}

</style>